@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  font-family: 'Roboto', sans-serif;

}
body {
  background-color: #000;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.bTag {
  font-family: Open Sans, Arial, sans-serif;
  font-size: 14px;
  color: #666;
  /* background-color: #B8BCC0; */
  line-height: 1.7em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;

  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;

}




.pageCon {
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 42%, rgb(0, 0, 0) 100%);

  /* min-height: 2013.5px; */
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;

  overflow: hidden;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


.mainContent {
  
  overflow-x: hidden;
  background-size: cover !important;
  background-position: top !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed;

  width: 100%;
  height: 100%;
  display: block;
}


.App {
  font-family: sans-serif;
  padding: 15%;
}

.logo{
  height:80px;
}

.information_bg{
  height: 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;

    background-size: cover;
}


.containerz{
  transform: translateY(130px)
}

.joincommunity{
  background-color: #07c5d3;
  border-radius: 20px;
  padding: 60px;
}  


.join_h1{
  color: #03040B;
  font-size: 30px;
  font-weight: 700;
  line-height: 70px;

}

@media screen and (min-width:900px) {
  .join_h1{
    color: #03040B;
    font-size: 45px;
    line-height: 70px;
  
  }
}
.join_p{
  color: #03040B;
  font-size: 14px;

}
@media screen and (min-width:900px) {
  .join_p{
    color: #03040B;
    font-size: 20px;
    
  
  }
}
.myButton {
  background-color:transparent;
  

  border-radius:9px;
  display:inline-block;
  cursor:pointer;
  color:#03040B;

  font-size:11px;

  font-weight: 900;
  padding:20px 22px;
  text-decoration:none;
  border: 2px solid #03040B;

}
@media screen and (min-width:900px) {
  .myButton {
  
    
 
    font-size:14px;
 
  }
}
.myButton:hover {
  background-color:#03040B;
  color:#fff;
}
.myButton:active {
  position:relative;
  top:1px;
}










.BlueButton{
  background-color:#2eecf3;
  

  border-radius:9px;
  display:inline-block;
  cursor:pointer;
  color:#03040B;
 
  font-size:11px;
font-style: normal;
font-weight: bolder;
  font-weight: 900;
  padding:20px 22px;
  text-decoration:none;


}
@media screen and (min-width:900px) {
  .BlueButton {
  
    
 
    font-size:14px;
 
  }
}
.BlueButton:hover {
  background-color:#03040B;
  color:#fff;
  border: 2px solid #faff00;
}
.BlueButton:active {
  position:relative;
  top:1px;
}



.containee{
  transform: translateY(17px)
}
.contain{
  transform: translateY(150px)
}
.fContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
img.F1Log {
  margin-top: 10px;
  width: 12%;
  margin-right: 5%;
  margin-left: 5%;
}
img.F2Log {
  /* height: 50px; */
  width: 12%;
  margin-right: 5%;
  margin-left: 5%;
}
img.F3Log {
  height: 100%;
  width: 12%;
  width:auto;
}

.partner_text{
  margin-top: 10px;
  width: 12%;
  height: 100%;
  margin-right: 5%;
  margin-left: 5%;
}





.intro-form-exchange {
  padding: 50px !important;
  box-shadow: 0 20px 25px rgba(14, 255, 255, 0.815);
  border-radius: 10px;
  background: #4343;

}
.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #a1a0a7;
  margin-bottom: 10px;
  background: transparent;
  border-color: #363c4e;
}

.mb-3, .my-3 {
  margin-bottom: 1rem!important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.form-group {
  position: relative;
}

.form-group {
  margin-bottom: 1rem;
}



.intro-form-exchange .btn {
  padding: 10px 15px;
  font-weight: 500;
  position: relative;
  font-size: 16px;
}




select.form-control {
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: #dededf;
  transition: all .3s ease-in-out;
  background: #000;
}





input.form-control {
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: #f0f0f0;

  transition: all .3s ease-in-out;
  background: rgb(0, 0, 0);
}


.auth-form {
  border: 0 solid #363c4e;
}
.card {
  
  margin-bottom: 20px !important;
  border-radius: 5px !important;
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%);
 
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
 
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125) !important;
  border-radius: 0.25rem;
}


.card-title {
  font-size: 20px;
  margin-bottom: 0;
  justify-content: center!important;
  color: #fff
  ;
}


.card .card-body {
  padding: 15px 20px;
  background: #131722 !important;
  border-radius: 15px;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
 
}
.justify-content-center {
  justify-content: center!important;
}



.fXWrYC, .fXWrYC:after, .fXWrYC:before {
  height: 2px;
  pointer-events: none;
  display: block;
  content: '';
  width: 100%;
  background-color: #ffffff !important;
  position: absolute;
}

.nav-link{
  font-size: 16px !important;
}

.myButtons{
	
	
	background-color:#ffffff;
	border-radius:32px;
	border:1px solid #f7f3f3;
	display:inline-block;
	cursor:pointer;
	color: #ffae00 !important;

	font-size:16px;
	font-weight:500 !important;
	padding:10px 30px;
	text-decoration:none;
}
.myButton {
	box-shadow: 2px 1px 20px 2px #899599;
	
	background-color:#ffffff;
	border-radius:32px;
	border:1px solid #f7f3f3;
	display:inline-block;
	cursor:pointer;
	color: #ffce0a !important;

	font-size:16px;
	font-weight:600 !important;
	padding:7px 25px;
	text-decoration:none;
}

        



.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
  color: #000 !important;
}












.waviy {
  position: relative;
 
}
.waviy span {
  position: relative;
  display: inline-block;
  

  color:gold;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(.2s * var(--i))
}
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg) 
    
  }
}









        



.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
  color: #000 !important;
}





.hwrap {
	overflow: hidden; /* HIDE SCROLL BAR */
	background: #4242; /* SET BACKGROUND COLOR */
  }
   
  /* (B) MOVING TICKER WRAPPER */
  .hmove { display: flex; }
  
  /* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
  .hitem {
	flex-shrink: 0;
	width: 100%;
	color: #a000eb;
	box-sizing: border-box;
	padding: 20px;
  font-size: 14px;
  font-weight: 600;
	text-align: center;
  }
  @media screen and (min-width:1000px) {
    .hitem {
      font-size: 20px;
      font-weight: 800;
      width: 30%;
     
      
      }
  }
  .hitems   {
    flex-shrink: 0;
    width: 100%;
    color: #0DE63C;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    font-size: 0.5rem;

    }

    @media screen and (min-width:1000px) {
      .hitems   {
        font-size: 1rem;
        width: 50%;
        }
    }
    
     
   
  /* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
  /* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
  @keyframes tickerh {
	0% { transform: translate3d(100%, 0, 0); }
	100% { transform: translate3d(-400%, 0, 0); }
  }
  .hmove { animation: tickerh linear 40s infinite; }
  .hmove:hover { animation-play-state: running; }

  @media only screen and (max-width: 768px) {
	.hmove { animation-duration: 20s; }
  }


  .nav-link{
    font-size: 16px !important;
  }


  .x-page-contact .x-contact__links-row {
    display: flex;
    width: 100%;
}
.x-page-contact .x-contact__links-item {
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  font-weight: 300;
  opacity: 0;
  text-decoration: none!important;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  transition: all .15s ease;
  width: 33%;
  z-index: 100;
}

.x-page-contact .x-contact__links-item--twitter>span {
  background: #1da1f2;
  outline: 0 solid #1da1f2;
}
.x-page-contact .x-contact__links-item svg {
  margin-bottom: 24px;
  width: 32px;
}
.x-page-contact .x-contact__links-item-label {
  height: 15px;
}



.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none!important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #fff !important;
}


.contactbg{
  background-image: url('./component/Image/cot.PNG');
  background-repeat: no-repeat;
background-size: cover;
background-position-x: center;




}

.brandbg{
  background: rgba(80, 80, 80, 0.158)url('./component/Image/hand.jpg');
  background-blend-mode: darken;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; 
  background-size: cover; 
}


.aboutbg{
  background: rgba(0, 0, 0, 0.959)url('./component/Image/abo.PNG');
  background-blend-mode: darken;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; 
  background-size: cover; 
}


.aboutbgt{
  background: rgb(37, 28, 28)url('./component/Image/bo.jpg');
  background-blend-mode: darken;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; 
  background-size: cover; 
}


.grbg{
  background-image: linear-gradient(283deg,#000000,#000000);
  margin-top: -152px;
  padding: 96px 0;
  height: 100%;
  position: relative;
}


.grt{
  background-image: linear-gradient(283deg,#000000,#000000);
  content: "";
  height: 340px;
  left: 40px;
  opacity: .7;
  position: absolute;
  top: -40px;
  width: calc(100% - 80px);
}

.map{
  align-items: center;
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 10%;
  position: relative;
}



.gtext{
  -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(left,#a469f2,#98bce8);
    -webkit-background-clip: text;
}

.halo{
  position: relative;
  top: -300px;
}



.up-down{
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: up-down linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: up-down linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: up-down linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: up-down linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

@keyframes up-down{
  0% {
    transform:  translate(1px,20px)  ;
  }
  24% {
    transform:  translate(1px,30px)  ;
  }
  50% {
    transform:  translate(1px,12px)  ;
  }
  74% {
    transform:  translate(1px,22px)  ;
  }
  100% {
    transform:  translate(1px,22px)  ;
  }
}

@-moz-keyframes up-down{
  0% {
    -moz-transform:  translate(1px,20px)  ;
  }
  24% {
    -moz-transform:  translate(1px,30px)  ;
  }
  50% {
    -moz-transform:  translate(1px,12px)  ;
  }
  74% {
    -moz-transform:  translate(1px,22px)  ;
  }
  100% {
    -moz-transform:  translate(1px,22px)  ;
  }
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform:  translate(1px,20px)  ;
  }
  24% {
    -webkit-transform:  translate(1px,30px)  ;
  }
  50% {
    -webkit-transform:  translate(1px,12px)  ;
  }
  74% {
    -webkit-transform:  translate(1px,22px)  ;
  }
  100% {
    -webkit-transform:  translate(1px,22px)  ;
  }
}

@-o-keyframes up-down {
  0% {
    -o-transform:  translate(1px,20px)  ;
  }
  24% {
    -o-transform:  translate(1px,30px)  ;
  }
  50% {
    -o-transform:  translate(1px,12px)  ;
  }
  74% {
    -o-transform:  translate(1px,22px)  ;
  }
  100% {
    -o-transform:  translate(1px,22px)  ;
  }
}

@-ms-keyframes up-down {
  0% {
    -ms-transform:  translate(1px,20px)  ;
  }
  24% {
    -ms-transform:  translate(1px,30px)  ;
  }
  50% {
    -ms-transform:  translate(1px,12px)  ;
  }
  74% {
    -ms-transform:  translate(1px,22px)  ;
  }
  100% {
    -ms-transform:  translate(1px,22px)  ;
  }
}



.form-outline.form-white .form-control {
  color: #5451ff !important;
}



brandbg
.MuiTableCell-body {
  color: rgb(0, 0, 0) !important;
}









.pageConss {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 42%, rgb(255, 255, 255) 100%);

  /* min-height: 2013.5px; */
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;

  overflow: hidden;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}







.h{
position: relative;
left: 0px;
}






@media screen and (min-width:1200px) {
  .h{
    position: relative;
    left: 300px;

  }
}

.m{
  position: relative;
  right: 0px;
}




@media screen and (min-width:1200px) {
  .m{
    position: relative;
    right: 300px;

  }
}



.parabrand{
  position: relative;
  left:18px;
  font-size: 13px;
}








@media screen and (min-width:1200px) {
  .parabrand{
    position: relative;
    left: 300px;
    font-size: 18px;

  }
}










.flip-card {
  background-color: transparent;
  width: 400px;
  height: 500px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;

  transition: transform 0.6s;
  transform-style: preserve-3d;
 
}

.flip-card:focus {
    outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner{
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-back {
 
  color: rgb(0, 0, 0);
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}








.slidetext   {
  font-size: 15px;
 
  }







@media screen and (min-width:1000px) {
  .slidetext   {
    font-size: 28px;
   
    }
}




h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  width: 300px !important;
 font-size: 22px !important;
}





@media screen and (min-width:1000px) {
  
h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  width: 900px !important;
  font-size: 28px !important;
}

}