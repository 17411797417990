.jumbotron {
    background: url("../src/component/Image/home.jpg")center;
    background-size: cover;
    background-attachment:fixed;
    padding: 40px;
    filter: brightness(65%);
/*    background: url("body2.jpg");
*/}
#collections {
    background: url("../src/component/Image/collectionbg.jpg")center;
    background-size: cover;
    padding-bottom: 10%;
    background-attachment:fixed;

/*    background: url("body2.jpg");
*/}
  #title{
      font-size: 70px;
  }